import request from '@/utils/http'

/**
 * 登录
 * @param {*} "password"
              "phone"
 * @returns
 */
export const login = ({ ...data }) => {
  return request({
    url: '/employee/login',
    method: 'POST',
    data
  })
}
/**
 * 注册
 * @param {*} "password"
              "phone"
 * @returns
 */
export const register = ({ ...data }) => {
  return request({
    url: '/employee/register',
    method: 'POST',
    data
  })
}
