<!--  -->
<template>
  <div class="bg">
    <div class="bg_small container">
      <div class="bg_mini title-wrap">
        <div class="title">
          <span>点达</span>
          <span>协同</span>
        </div>
        <div class="desc">从此爱上工作</div>
      </div>
      <div class="login-wrap">
        <div class="company-title">保定市点达网络科技有限公司</div>
        <div class="login-box">
          <div class="login-box-bg">
            <span class="corner1"></span>
            <span class="corner2"></span>
            <span class="corner3"></span>
            <span class="corner4"></span>
            <div class="input_group login_title">{{handleType?'用户登录':'用户注册'}}</div>
            <div class="input_group input_phone">
              <input v-model="params.phone" type="text" placeholder="请输入手机号" />
            </div>
            <div class="input_group input_pwd">
              <input
                v-model="params.password"
                :type="isShowEye?'text':'password'"
                placeholder="请输入密码"
              />
              <i
                @click="handleEye"
                v-show="!handleType"
                class="iconfont"
                :class="isShowEye?'icon-kejian':'icon-bukejian'"
              ></i>
            </div>
            <div v-show="!handleType" class="input_group input_invite_code">
              <input type="text" placeholder="请输入邀请码" />
            </div>
            <!-- <div v-show="handleType" class="input_group input_handle_login">
              <div class="remember_pwd">
                <el-checkbox>记住密码</el-checkbox>
              </div>
              <div class="toggle_jump" @click="handleToggle(0)">
                <span>注册</span>
              </div>
            </div>-->
            <div v-show="!handleType" class="input_group input_handle_register">
              <div class="toggle_jump" @click="handleToggle(1)">
                <span>登陆</span>
              </div>
            </div>
            <div class="input_group login_btn">
              <button @click="handleBtn">{{handleType?'马上登录': '立即注册'}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, register } from "@/api/RegisterController";
import { cleanParams } from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      handleType: 1, // 1:login 0:register
      isShowEye: false,
      params: {
        phone: "15930722692",
        password: "123456"
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (vm.$store.state.Login.loginInfo) {
        vm.$router.go(-1);
      }
    });
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleEye() {
      this.isShowEye = !this.isShowEye;
    },
    // 切换登陆注册
    handleToggle(type) {
      this.handleType = type;
      cleanParams(this.params);
    },
    // 操作按钮
    handleBtn() {
      if (this.handleType) {
        this.login();
      } else {
        this.register();
      }
    },
    async login() {
      let [err, res] = await this.$awaitWrap(login(this.params));
      console.log(err, res);
      if (err) {
        this.$message.error(err.msg);
        return;
      }
      if (res) {
        this.$store.commit("Login/saveLogin", res.data);
        this.$router.push({
          path: "/mine"
        });
      }
    },
    async register() {
      let [err, res] = await this.$awaitWrap(register(this.params));
      if (err) {
        this.$message.error(err.msg);
        return;
      }
      console.log(err, res);
    }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    /* this.$axios
      .post("http://app-interface-test.showyou.xin/employee/login", {
        phone: "15930722692",
        password: "123456"
      })
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      }); */
    /* login({
      phone: "15930722692",
      password: "123456"
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      }); */
  }
};
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("./index.less");
</style>